<template>
  <b-container>
    <b-card>
      <h1 class="mb-4">Welcome {{ firstName }} to the IFAC Affiliates portal</h1>
      <p>
        As an IFAC Affiliate, you are eligible for the following services by and for IFAC:
      </p>
      <ul>
        <li>
          Benefit for reduced registration fees at IFAC Conferences.
          Conferences are typically EUR 10 (or the local equivalent) less expensive for IFAC Affiliates,
          than for non-affiliates.
        </li>
        <li>
          Participating in
          <a
            class="text-decoration-none" target="_blank"
            href="https://www.ifac-control.org/areas">IFAC Technical Activities</a>.
        </li>
        <li>
          Organizing <router-link class="text-decoration-none" to="/conferences/apply">IFAC Conferences</router-link>
          (IFAC shall require Organizers and IPC members to be IFAC Affiliates).
        </li>
        <li>
          Participating in <a
            href="https://www.ifac-control.org/publications/journals"
            class="text-decoration-none" target="_blank">IFAC Journals</a>
          (IFAC shall require Editors and Associate Editors to be IFAC Affiliates).
        </li>
        <li>
          Receiving <a class="text-decoration-none" href="https://www.ifac-control.org/awards" target="_blank">IFAC Awards</a>.
        </li>
        <li>
          Apply to the <a
            class="text-decoration-none" target="_blank"
            href="https://www.ifac-control.org/areas/activity-fund">IFAC Activity fund</a>.
        </li>
      </ul>
      <p>
        The portal gives you access to dedicated IFAC services based on your situation and your field/s of interest.
        You are invited to fill in as much as is necessary and as much as you wish on the
        <router-link class="text-decoration-none" to="/account/edit-profile">Edit Profile</router-link> section.
        Based on your settings you shall Receive the <a
          class="text-decoration-none" target="_blank"
          href="https://www.ifac-control.org/publications/newsletter_archives">
        IFAC Newsletter</a> (published and distributed six times per year),
        Receiving alerts about the IFAC Conferences
        <router-link class="text-decoration-none" to="/account/edit-profile">in your field/s of interest</router-link>,
        Be contactable by other IFAC Affiliates, or IFAC Executive Officers and other IFAC officials, for new scientific collaborations.
      </p>
      <p>
        Note, for the data that you provide in this portal, you have to make the choice between three levels of confidentiality
      </p>
      <ul>
        <li>
          Web public: the information is available to anyone who visits the portal, even non IFAC Affiliated visitors.
          It can be useful to show you belong to the IFAC community and to show your expertise outside of the Control Engineering sphere.
        </li>
        <li>
          IFAC restricted: The information is available to IFAC Affiliates only (once they are logged in to the portal).
          It can be useful to connect with colleagues in your field/s of interest, to be contacted to join Technical Committees,
          or other IFAC Activities.
        </li>
        <li>
          Private: The information shall not be disclosed to anyone and only used by the IFAC Secretariat for internal IFAC purpose.
          This can be useful for personal reasons, information that you wish to keep for a time secret and reveal afterwards, etc.
        </li>
      </ul>
      <p>
        IFAC shall never make any commercial use of this information. It shall not be stored without your consent.
        IFAC leaves it to you to update, delete or enhance your information, all at your convenience.
      </p>
      <p>
        For any additional information, or if you require any assistance, please contact the IFAC Secretariat at
        <a class="text-decoration-none" href="mailto:secretariat@ifac-control.org">secretariat@ifac-control.org</a>.
      </p>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    firstName() {
      return this.user?.name;
    },
  },
};
</script>

<style>
</style>
